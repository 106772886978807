dmx.slideshow.transitions.overlap = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        direction: 'random', // left, right, up, down

        method: 'random', // reveal, hide

        duration: 600,

        setup: function() {
            if (this.options.direction === 'random') {
                this.options.direction = this.random('left', 'right', 'up', 'down');
            }

            if (this.options.method === 'random') {
                this.options.method = this.random('reveal', 'hide');
            }

            this.slide = document.createElement('div');
            this.slide.style.setProperty('position', 'absolute');
            this.slide.style.setProperty('width', '100%');
            this.slide.style.setProperty('height', '100%');
            this.slide.style.setProperty('background-size', '100%');
            this.slide.style.setProperty('background-image', 'url("' + (this.options.method == 'hide' ? this.nextSlide : this.prevSlide).url + '")');
            this.slide.style.setProperty('transition', 'all ' + this.options.duration + 'ms linear');

            if (this.options.method == 'hide') {
                this.slide.style.setProperty('left', this.options.direction == 'left' ? '100%' : this.options.direction == 'right' ? '-100%' : 0);
                this.slide.style.setProperty('top', this.options.direction == 'up' ? '100%' : this.options.direction == 'down' ? '-100%' : 0);
            }

            slideshow.effectsContainer.appendChild(this.slide);
            if (this.options.method == 'reveal') {
                slideshow.showImage(this.index);
            }
        },

        execute: function() {
            switch (this.options.direction) {
                case 'left': this.slide.style.setProperty('transform', 'translate(-100%, 0)'); break;
                case 'right': this.slide.style.setProperty('transform', 'translate(100%, 0)'); break;
                case 'up': this.slide.style.setProperty('transform', 'translate(0, -100%)'); break;
                case 'down': this.slide.style.setProperty('transform', 'translate(0, 100%)'); break;
            }
            this.slide.addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
