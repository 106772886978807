dmx.slideshow.transitions.zoom = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        scale: 1.5,

        duration: 600,

        setup: function() {
            this.slide = document.createElement('div');
            this.slide.style.setProperty('position', 'absolute');
            this.slide.style.setProperty('width', '100%');
            this.slide.style.setProperty('height', '100%');
            this.slide.style.setProperty('background-size', this.width + 'px');
            this.slide.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
            this.slide.style.setProperty('transition', 'all ' + this.options.duration + 'ms linear');

            slideshow.effectsContainer.appendChild(this.slide);
            slideshow.showImage(this.index);
        },

        execute: function() {
            this.slide.style.setProperty('opacity', 0);
            this.slide.style.setProperty('transform', 'scale(' + this.options.scale + ')');
            this.slide.addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
