dmx.slideshow.transitions.wipe = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        direction: 'random', // left, right, up, down

        duration: 600,

        setup: function() {
            if (this.options.direction === 'random') {
                this.options.direction = this.random('left', 'right', 'up', 'down');
            }

            this.slide = document.createElement('div');
            this.slide.style.setProperty('position', 'absolute');
            this.slide.style.setProperty('width', '100%');
            this.slide.style.setProperty('height', '100%');
            this.slide.style.setProperty('background-size', this.width + 'px');
            this.slide.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
            this.slide.style.setProperty('background-position', (this.options.direction == 'right' ? 'right' : 'left') + ' ' + (this.options.direction == 'down' ? 'bottom' : 'top'));
            this.slide.style.setProperty('left', this.options.direction == 'right' ? 'auto' : 0);
            this.slide.style.setProperty('right', this.options.direction == 'left' ? 'auto' : 0);
            this.slide.style.setProperty('top', this.options.direction == 'down' ? 'auto' : 0);
            this.slide.style.setProperty('bottom', this.options.direction == 'up' ? 'auto' : 0);
            this.slide.style.setProperty('transition', 'all ' + this.options.duration + 'ms linear');

            slideshow.effectsContainer.appendChild(this.slide);
            slideshow.showImage(this.index);
        },

        execute: function() {
            this.slide.style.setProperty(this.options.direction == 'left' || this.options.direction == 'right' ? 'width' : 'height', 0);
            this.slide.addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
