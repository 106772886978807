dmx.slideshow.transitions.blocks = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        cols:  10,

        rows: 'auto',

        delay: 60,

        duration: 600,

        scale: 0.8,

        setup: function() {
            var width = this.width / this.options.cols;
            if (this.options.rows == 'auto') this.options.rows = Math.round(this.height / width);
            var height = this.height / this.options.rows;
            var fragment = document.createDocumentFragment();

            for (var y = 0; y < this.options.rows; y++) {
                for (var x = 0; x < this.options.cols; x++) {
                    var block = document.createElement('div');
                    block.style.setProperty('position', 'absolute');
                    block.style.setProperty('width', width + 'px');
                    block.style.setProperty('height', height + 'px');
                    block.style.setProperty('left', (x * width) + 'px');
                    block.style.setProperty('top', (y * height) + 'px');
                    block.style.setProperty('background-size', this.width + 'px');
                    block.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
                    block.style.setProperty('background-position', '-' + (x * width) + 'px -' + (y * height) + 'px');
                    block.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in ' + ((x + y) * this.options.delay) + 'ms');

                    fragment.appendChild(block);
                }
            }

            slideshow.effectsContainer.appendChild(fragment);
            slideshow.showImage(this.index);
        },

        execute: function() {
            var blocks = slideshow.effectsContainer.childNodes;

            for (var i = 0; i < blocks.length; i++) {
                blocks[i].style.setProperty('opacity', 0);
                blocks[i].style.setProperty('transform', 'scale(' + this.options.scale + ')');
            }

            blocks[blocks.length - 1].addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration + ((this.options.cols + this.options.rows - 2) * this.options.delay));
        }
    }, options));
};
