dmx.slideshow.transitions.zip = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        bars:  10,

        delay: 40,

        duration: 400,

        direction: 'random', // left, right

        setup: function() {
            if (this.options.direction === 'random') {
                this.options.direction = this.random('left', 'right');
            }

            var width = this.width / this.options.bars;
            var fragment = document.createDocumentFragment();

            for (var i = 0; i < this.options.bars; i++) {
                var ix = this.options.direction == 'left' ? this.options.bars - i - 1 : i;
                var bar = document.createElement('div');
                bar.style.setProperty('position', 'absolute');
                bar.style.setProperty('width', width + 'px');
                bar.style.setProperty('height', '100%');
                bar.style.setProperty('left', (ix * width) + 'px');
                bar.style.setProperty('background-size', this.width + 'px');
                bar.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
                bar.style.setProperty('background-position', '-' + (ix * width) + 'px 0px');
                bar.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in-out ' + (i * this.options.delay) + 'ms');

                fragment.appendChild(bar);
            }

            slideshow.effectsContainer.appendChild(fragment);
            slideshow.showImage(this.index);
        },

        execute: function() {
            var bars = slideshow.effectsContainer.childNodes;

            for (var i = 0; i < bars.length; i++) {
                bars[i].style.setProperty('opacity', 0.5);
                bars[i].style.setProperty('transform', i % 2 === 0 ? 'translateY(100%)' : 'translateY(-100%)');
            }

            bars[bars.length - 1].addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration + ((bars.length - 1) * this.options.delay));
        }
    }, options));
};
