dmx.slideshow.transitions.concentric = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        circles: 5,

        duration: 800,

        delay: 150,

        direction: 'random', // left, right, alternate

        setup: function() {
            if (this.options.direction === 'random') {
                this.options.direction = this.random('left', 'right', 'alternate');
            }

            var d = Math.sqrt(this.width * this.width + this.height * this.height);
            var s = Math.ceil(d / this.options.circles);

            var fragment = document.createDocumentFragment();

            for (var i = 0; i < this.options.circles; i++) {
                var size = (i * s) + s;
                var div = document.createElement('div');
                div.style.setProperty('position', 'absolute');
                div.style.setProperty('width', size + 'px');
                div.style.setProperty('height', size + 'px');
                div.style.setProperty('top', '50%');
                div.style.setProperty('left', '50%');
                div.style.setProperty('margin-top', '-' + (size / 2) + 'px');
                div.style.setProperty('margin-left', '-' + (size / 2) + 'px');
                div.style.setProperty('border-radius', '50%');
                div.style.setProperty('z-index', this.options.circles - i);
                div.style.setProperty('background-size', this.width + 'px');
                div.style.setProperty('background-position', 'center center');
                div.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
                div.style.setProperty('transition', 'all ' + this.options.duration + 'ms linear ' + ((this.options.circles - i - 1) * this.options.delay) + 'ms');

                fragment.appendChild(div);
            }

            slideshow.effectsContainer.appendChild(fragment);
            slideshow.showImage(this.index);
        },

        execute: function() {
            var divs = slideshow.effectsContainer.childNodes;

            for (var i = 0; i < divs.length; i++) {
                divs[i].style.setProperty('opacity', 0);
                divs[i].style.setProperty('transform', 'rotateZ(' + (this.options.direction == 'left' || (this.options.direction == 'alternate' && i%2) ? '-' : '') + '180deg)');
            }

            divs[0].addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration + ((divs.length - 1) * this.options.delay));
        },

        after: function() {
        }
    }, options));
};
